<template>
  <div class="scheme-contain">
    <div class="data-contain">
      <div class="card-wrapper">
        <div class="monitor-search">
          <div class="monitor-date">
            <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" v-model="foodDate" @on-change="changeDate"></Date-picker>
          </div>
          <div class="tab-contain">
            <router-link class="static-button" :to="{name: 'FoodTable'}">数据显示</router-link>
            <router-link class="chart-button" :to="{name: 'FoodChart', params: {page: this.curPage}}">图表显示</router-link>
          </div>
        </div>
      </div>
      <div class="add-box">
        <Row>
          <Col span="2" offset="22">
            <div class="member-oprate" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">
              <a class="new-doc fr" @click='clickAdd'>添加信息</a>
            </div>
          </Col>
        </Row>
      </div>

      <div class="table-box">
        <Table :columns="columns" :data="foodList" ref="table" @on-selection-change="handleSelect"></Table>


        <Row>
          <Col span="12">
            <div class="table-oprate">
              <!-- <Checkbox>全选</Checkbox> -->
              <Button style="background: #fff;" @click="handleAllRemove" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
            </div>
          </Col>
          <Col span="12">
            <span class="records">共{{ totalSize }}条记录</span>
          </Col>
        </Row>
      </div>

      <div class="page-nations-wrapper">
        <div class="page-nations">
          <Page :total="totalSize" :current="curPage" :page-size="sizePerPage" @on-change="changePage"></Page>
        </div>
      </div>

    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			curPage: 1,
			totalSize: 0,
			sizePerPage: 10,
			foodDate: '',
			index: 1,
			monitor_id: '',
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '日期', key: 'create_time', align: 'center' },
				{ title: '早餐能量', key: 'breakfast', align: 'center' },
				{ title: '中餐能量', key: 'lunch', align: 'center' },
				{ title: '晚餐能量', key: 'dinner', align: 'center' },
				{ title: '加餐能量', key: 'add', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (
							this.power.indexOf('会员管理--删除会员信息权限') > -1 &&
							this.power.indexOf('会员管理--编辑会员信息权限') > -1
						) {
							return h('div', [
								h('a', {
									class: 'icon_eye',
									on: {
										click: () => {
											this.$router.push({
												name: 'FoodRecordList',
												params: { food_id: this.foodList[params.index].id },
											});
										},
									},
								}),
								h('a', {
									class: 'icon_edit',
									on: {
										click: () => {
											sessionStorage.setItem('food_id', this.foodList[params.index].id);
											sessionStorage.setItem('foodDate', this.foodList[params.index].create_time);
											this.$router.push({ name: 'FoodRecordAdd', params: { type_id: 0 } });
										},
									},
								}),
								h('a', {
									class: 'icon_del',
									on: {
										click: () => {
											this.$Modal.confirm({
												title: '提醒',
												content: '确定删除该数据吗',
												onOk: () => {
													this.handleRemove(params.index);
												},
											});
										},
									},
								}),
							]);
						} else if (this.power.indexOf('会员管理--编辑会员信息权限') > -1) {
							return h('div', [
								h('a', {
									class: 'icon_eye',
									on: {
										click: () => {
											this.$router.push({
												name: 'FoodRecordList',
												params: { food_id: this.foodList[params.index].id },
											});
										},
									},
								}),
								h('a', {
									class: 'icon_edit',
									style: {
										marginRight: 0,
									},
									on: {
										click: () => {
											sessionStorage.setItem('food_id', this.foodList[params.index].id);
											sessionStorage.setItem('foodDate', this.foodList[params.index].create_time);
											this.$router.push({ name: 'FoodRecordAdd', params: { type_id: 0 } });
										},
									},
								}),
							]);
						} else {
							return h('div', [
								h('a', {
									class: 'icon_eye',
									style: {
										marginRight: 0,
									},
									on: {
										click: () => {
											this.$router.push({
												name: 'FoodRecordList',
												params: { food_id: this.foodList[params.index].id },
											});
										},
									},
								}),
							]);
						}
					},
				},
			],
			foodList: [
				{
					name: '',
					type: '',
					way: '',
					start_time: '',
					end_time: '',
				},
			],
			deleteList: [],
			power: [],
		};
	},

	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
		monitorId() {
			return this.$route.params.monitorId;
		},
	},

	created() {
		this.power = this.userInfo.power;
		this.foodDate = '';
		this.monitor_id = this.monitorId;
		this.queryFoodRecode();
		// var req = {
		//   member_id: this.memberId,
		//   index: this.index,
		//   page: this.curPage,
		//   size: this.sizePerpage
		// }
		// MemberService.queryFood(req).then((data) => {
		//   console.log('----- data.list' + JSON.stringify(data.list))
		//   this.foodList = data.list
		//   this.totalSize = data.row_size
		//   this.curPage = data.cur_page
		// })
	},

	methods: {
		queryFoodRecode() {
			var req = {
				member_id: this.memberId,
				index: this.index,
				start: this.foodDate[0],
				end: this.foodDate[1],
				page: this.curPage,
				size: this.sizePerPage,
				monitor_id: this.monitorId,
			};
			MemberService.queryFood(req).then((data) => {
				this.foodList = data.list;
				this.foodList.forEach((item) => {
					item.breakfast = `${item.breakfast}kcal`;
					item.lunch = `${item.lunch}kcal`;
					item.dinner = `${item.dinner}kcal`;
					item.add = `${item.add}kcal`;
				});
				this.totalSize = data.row_size;
				this.curPage = data.cur_page;
				this.deleteList = [];
			});
		},
		changeDate(date) {
			this.foodDate = date;
			this.queryFoodRecode();
		},
		changePage(page) {
			this.curPage = page;
			this.queryFoodRecode();
		},
		// table勾选
		handleSelect(selection) {
			this.deleteList = [];
			selection.forEach((item) => {
				this.deleteList.push(item.id);
			});
			console.log('----li_list = ' + JSON.stringify(this.deleteList));
		},
		handleLook() {
			this.$router.push({ name: 'FoodRecordList' });
		},
		clickAdd() {
			sessionStorage.removeItem('food_id');
			sessionStorage.removeItem('foodDate');
			this.$router.push({ name: 'FoodRecordAdd', params: { type_id: 0 } });
		},
		handleRemove(index) {
			let req = [this.foodList[index].id];
			MemberService.delFood({ id_str: JSON.stringify(req) }).then(() => {
				this.queryFoodRecode();
				this.$Message.warning('删除成功');
			});
		},
		// 删除多个成药与要素数据
		handleAllRemove() {
			if (this.deleteList.length === 0) {
				this.$Message.warning('请先勾选要删除的数据');
			} else {
				this.$Modal.confirm({
					title: '提醒',
					content: '确定删除所选数据吗',
					onOk: () => {
						this.handleConfirmDelete();
					},
				});
			}
		},
		handleConfirmDelete() {
			console.log('----li_list = ' + JSON.stringify(this.deleteList));
			MemberService.delFood({ id_str: JSON.stringify(this.deleteList) }).then(() => {
				this.deleteList = [];
				this.queryFoodRecode();
				this.$Message.warning('删除成功');
			});
		},
	},
};
</script>
<style lang="css" scoped>
.data-contain .table-box {
  margin: 0;
}
</style>
